import React, { useState, useContext, Fragment, useEffect } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faLuggageCart,
} from "@fortawesome/free-solid-svg-icons";
import { Oval } from "react-loader-spinner";
import OrderItemCart from "../../components/LoadCartItem";

import AuthContext from "../../context/authContext";
import OrderContext from "../../context/orderContext";
import Header from "../../components/Header";
import LoginPage from "../login";
import { fetchCart, deleteCart, createOrderV2 } from "../../services/api";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { Colors } from "../../utils/styles";
import { ROUNDED_BUTTON } from "../../utils/styles/buttons";
import ModalContext from "../../context/modalContext";
import { ToastContainer, toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import styles from "../customorder/style";
import { allNigeriaStatesOptions } from "../../utils/utilities";

import "./style.scss";
import { useNavigate } from "react-router-dom";

const LoadCartPage = (props) => {
  const [cart, setCart] = useState([]);
  const [code, setCode] = useState("");
  const [order, setOrder] = useState(null);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [shippingType, setShippingType] = useState();
  const [shippingAd, setShippingAd] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const [modalShow, setModalShow] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const { isAuthenticated } = useContext(AuthContext);
  const { cartCode, setCartCode, setCartLen } = useContext(OrderContext);
  const [addPhoneNo, setaddPhoneNo] = useState(false);
  const [phoneNo, setphoneNo] = useState(0);
  const [selectedState, setSelectedState] = useState(null);
  const { setShowCustomModal } = useContext(ModalContext);
  const [isUkStore, setIsUkStore] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
  };

  const handleShowItWorks = () => {
    setShowCustomModal(true);
  };

  const handleShow = () => {
    setCart([]);
    setShow(true);
  };

  const validateForm = () => {
    if (code !== null) {
      return code.length > 0 || shippingAd !== "";
    } else {
      return cart.length > 0;
    }
  };

  const validatePlaceOrder = () => {
    let validate = true;

    if (shippingType === "pickup") {
      if (shippingAd.trim() !== "") {
        validate = false;
      }
    }

    if (shippingType === "door-to-door") {
      if (
        !addPhoneNo &&
        phoneNo === 0 &&
        shippingAd.trim() !== "" &&
        selectedState != null
      ) {
        validate = false;
      } else if (
        addPhoneNo &&
        phoneNo > 0 &&
        shippingAd.trim() !== "" &&
        selectedState != null
      ) {
        validate = false;
      } else if (
        !addPhoneNo &&
        shippingAd.trim() !== "" &&
        selectedState != null
      ) {
        validate = false;
      } else {
        validate = true;
      }
    }
    return validate;
  };

  const handleAddPhoneNumber = () => {
    setaddPhoneNo(!addPhoneNo);
  };

  const handleChangeNumber = (e) => {
    setphoneNo(e);
  };

  useEffect(() => {
    if (isAuthenticated) {
      loadCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // handles cart items from beta-eshopping extension
  const loadCart = async () => {
    setCartLoading(true);
    const params = new URL(document.location).searchParams;
    const code = params.get("code");
    setCode(code);
    const cart_res = await fetchCart(code);
    // console.log(cart_res.data);
    setIsUkStore(cart_res.data[0]?.cart?.is_uk_store);
    switch (true) {
      case cart_res.response?.status >= 400:
        setCart([]);
        break;
      case cart_res.data.length === 0:
        setCart([]);
        break;
      case cart_res.data.length === 2 && code !== null: // handles exisiting and new cart
        setCart(cart_res.data);
        setShowModal(true);
        break;
      case cart_res.data.length === 2 && code === null: // handle case where user has two carts not checked out
        await handleCartDelete(cart_res.data[1].cart.code); // deletes the initial unchecked cart to avoid two carts not checkout out
        setCode(cart_res.data[0].cart.code);
        setCart(cart_res.data[0].items);
        setCartCode(cart_res.data[0].cart.code);
        setShowModal(false);
        break;
      default:
        setCart(cart_res.data[0].items);
    }
    setCartLoading(false);
  };

  const handleCartDelete = async (code) => {
    await deleteCart(code);
  };

  const handleSelectCartItem = async (isExisting) => {
    const existingCart = cart.filter((ele) => ele.cart.is_existing_cart);
    const newCart = cart.filter((ele) => !ele.cart.is_existing_cart);

    const cartToDelete = isExisting
      ? existingCart[0].cart.code
      : newCart[0].cart.code;
    const cartItems = isExisting ? newCart[0].items : existingCart[0].items;

    await handleCartDelete(cartToDelete);
    setCart(cartItems);
    setShowModal(false);
  };

  const CartModal = () => {
    return (
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ justifyContent: "center" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontWeight: 600 }}
          >
            Old Cart Alert
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
            alignContent: "center",
          }}
        >
          <span style={{ width: 20 }}>
            You have an old cart, "Checkout Old cart" or continue with this
            order by tapping "Proceed with New cart".
          </span>
        </Modal.Body>
        <Modal.Footer
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() => {
              handleSelectCartItem(true);
            }}
            style={{
              borderRadius: 10,
              backgroundColor: Colors.BES_PURPLE,
              color: "#FFFFFF",
              fontSize: 16,
              width: 200,
              marginTop: 20,
              height: 45,
              border: "none",
              fontWeight: 600,
            }}
          >
            Proceed with New Cart
          </Button>
          <Button
            onClick={async () => {
              await handleSelectCartItem(false);
              window.location.replace("/load-cart");
            }}
            style={{
              borderRadius: 10,
              backgroundColor: Colors.BES_RED,
              color: "#FFFFFF",
              fontSize: 16,
              width: 200,
              marginTop: 20,
              height: 50,
              border: "none",
              fontWeight: 600,
            }}
          >
            Checkout Old Cart
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const shippingAddress = () => {
    if (phoneNo !== 0 && addPhoneNo && selectedState != null) {
      // eslint-disable-next-line no-useless-concat
      return (
        shippingAd +
        ", " +
        selectedState +
        " State " +
        "(Recipient No " +
        phoneNo +
        ")"
      );
    } else if (selectedState != null) {
      return shippingAd + ", " + selectedState + " State";
    } else {
      return shippingAd;
    }
  };

  const handlePlaceOrder = async () => {
    setIsLoading(true);
    const cartItems = cart.map(
      ({
        color,
        image_url: image,
        unit_price: price,
        quantity,
        size,
        url: item_url,
        name: title,
      }) => ({
        color,
        image,
        price: Number(price.replace(/[^0-9.-]+/g, "")),
        quantity,
        size,
        item_url,
        title,
      })
    );
    const orderData = {
      shipping_type: shippingType,
      isCartV2: true,
      items: cartItems,
      shipping_address: shippingAddress(),
      cart_code: code === null ? cartCode : code,
    };
    try {
      const order = await createOrderV2(orderData);
      setOrder(order.data);
      setCart([]);
      setCartLen(0);
      setCartCode("");
      // Navigate to checkout based on response
      if (order.data.is_currency_converted) {
        navigate(`/order/${order.data.order_id}/checkout`);
      } else {
        // we could not ascertain weight and shopping costs at the BE
        handleShow();
      }
    } catch (error) {
      if (error.response?.status >= 400) {
        toast.error(error.response.data.detail, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "light",
        });
        setError(error.response.data.error);
      }
    }
    setIsLoading(false);
  };

  const handleShippingType = (e) => {
    const val = e.target.value;
    setShippingType(val);

    switch (val) {
      case "door-to-door":
        setDisabled(shippingAd.length === 0);
        break;
      case "pickup":
        setDisabled(false);
        break;
      default:
        setShippingType();
        setDisabled(true);
    }
  };

  const handleTextArea = (e) => {
    e.preventDefault();
    setShippingAd(e.target.value);
    setDisabled(false);
  };

  const handlePickupOption = (e) => {
    setShippingAd(e.target.value);
  };

  const handleChangeQty = (action, singleItem) => {
    const { id, quantity } = singleItem;
    const newQty =
      action === "add"
        ? Number(quantity) + 1
        : Math.max(1, Number(quantity) - 1);
    const updateCart = cart.map((item) =>
      item.id === id ? { ...item, quantity: newQty } : item
    );
    setCart(updateCart);
  };

  const handleItemDelete = (id) => {
    setCart(cart.filter((item) => item.id !== id));
    toast.success("Item deleted successfully.", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "light",
    });
  };

  if (!isAuthenticated) {
    const urlPath = `/load-cart?code=${code}`;
    return <LoginPage path={urlPath} />;
  }

  return (
    <section style={{ height: "100vh" }}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
      <Header hideAuth={true} isAuthenticated={isAuthenticated} />
      {/* Success Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ justifyContent: "center" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontWeight: 600 }}
          >
            Order Placed Successfully
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ textAlign: "center", alignSelf: "center" }}
          className="mb-5"
        >
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="6x"
            color={Colors.BES_PURPLE}
            className="mb-5"
          />
          <p style={{ fontWeight: 600 }}>Order ID: {order?.order_id}</p>
          <span style={{ width: 20 }}>
            Your order has been received, it will be ready for payment within 2
            working hours. Kindly check pending orders in your profile if you
            did not get a mail notification.
          </span>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <a href="/history">
            <Button
              onClick={handleClose}
              style={{
                borderRadius: 10,
                backgroundColor: Colors.BES_PURPLE,
                color: "#FFFFFF",
                fontSize: 16,
                width: 200,
                marginTop: 20,
                height: 50,
                border: "none",
              }}
            >
              Done
            </Button>
          </a>
        </Modal.Footer>
      </Modal>

      {/* Error Modal */}
      {error && code !== null ? (
        <Modal
          {...props}
          size="md"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={() => setModalShow(!modalShow)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Oops!</Modal.Title>
          </Modal.Header>
          <Modal.Body>{error}</Modal.Body>
          <Modal.Footer>
            <Button
              size="md"
              style={{
                ...ROUNDED_BUTTON,
                width: 100,
                height: 40,
                margin: "0 auto",
              }}
              onClick={() => {
                setModalShow(!modalShow);
                setError("");
              }}
            >
              Got it!
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}

      {/* Cart Modal */}
      <CartModal />
      {/* content starts here */}
      <Container>
        {cartLoading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "90vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Row style={{ height: "100%" }}>
            <Col lg={8} style={{ marginTop: 50 }}>
              <div className="cart-box">
                {!isLoading && cart.length === 0 ? (
                  <div className="d-flex flex-column justify-content-center align-items-center cart-empty">
                    <i
                      className="fas fa-shopping-cart mb-3"
                      style={{
                        paddingTop: 200,
                        color: Colors.BES_PURPLE,
                        fontSize: 70,
                      }}
                    ></i>
                    <p
                      style={{
                        textAlign: "center",
                        color: "#CBCBCB",
                        fontSize: 16,
                        width: "100%",
                        margin: "0 auto",
                      }}
                    >
                      Your cart is empty. Shop on your favourite store and
                      checkout with the beta-eshopping extension.
                    </p>
                    <Button
                      size="lg"
                      onClick={handleShowItWorks}
                      style={{
                        borderRadius: 10,
                        backgroundColor: Colors.BES_PURPLE,
                        color: "#FFFFFF",
                        fontSize: 16,
                        width: "40%",
                        marginTop: 20,
                        marginBottom: "15rem",
                        height: 45,
                        border: "none",
                      }}
                      className="load-cart-button bes-input-box-shadow"
                    >
                      Get Started
                    </Button>
                  </div>
                ) : (
                  <div>
                    <OrderItemCart
                      items={cart}
                      is_uk_store={isUkStore}
                      onChangeQty={handleChangeQty}
                      onRemove={handleItemDelete}
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col
              lg={4}
              style={{ textAlign: "center", height: "100%", marginTop: 50 }}
            >
              <Fragment>
                <Row className="load-cart-shipping">
                  <div className="box">
                    <h5
                      className="load-cart-description"
                      style={{ fontWeight: 600, marginBottom: 10 }}
                    >
                      <FontAwesomeIcon
                        icon={faLuggageCart}
                        color={Colors.BES_PURPLE}
                        style={{ marginRight: 5 }}
                      />
                      Shipping Details
                    </h5>
                    <Col lg={12}>
                      <select
                        value={shippingType}
                        onChange={handleShippingType}
                        className="form-select form-select-lg mt-3 mb-3 bes-input-box-shadow"
                        style={{ fontSize: 16 }}
                        disabled={!validateForm()}
                      >
                        <option>Please select a shipping type</option>
                        <option>pickup</option>
                        <option>door-to-door</option>
                      </select>
                    </Col>
                    <Col lg={12}>
                      {shippingType === "door-to-door" && (
                        <div>
                          <textarea
                            placeholder="Enter delivery address and state"
                            className="mb-3 mt-3 form-control form-control-lg"
                            type="text"
                            onChange={(e) => handleTextArea(e)}
                            style={{ fontSize: 16 }}
                          />

                          <select
                            value={selectedState}
                            onChange={(event) =>
                              setSelectedState(event.target.value)
                            }
                            className="form-select form-select-lg mt-3 mb-3"
                            style={{ fontSize: 16 }}
                          >
                            <option value="">Select a state</option>
                            {allNigeriaStatesOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.value}
                              </option>
                            ))}
                          </select>

                          <div
                            className="d-flex align-items-center"
                            style={{ width: "100%", marginBottom: "1rem" }}
                          >
                            <input
                              style={{ marginRight: "0.4rem" }}
                              type="checkbox"
                              id="recipient-id"
                              name="recipient-id"
                              value="recipient-id"
                              onChange={() => handleAddPhoneNumber()}
                              checked={addPhoneNo}
                            />
                            <label>
                              {" "}
                              Add Recipient
                              <i
                                className="fa fa-question-circle text-danger mr-2"
                                aria-hidden="true"
                                title=" If the phone number you have for the recipient is different 
                      from the one on your profile, please give us the correct phone number for the recipient."
                              ></i>
                            </label>
                          </div>
                          {addPhoneNo === true && (
                            <PhoneInput
                              international
                              defaultCountry="NG"
                              placeholder="Enter Recipient Phone Number"
                              value={phoneNo}
                              onChange={(e) => handleChangeNumber(e)}
                              style={styles.formInput}
                              className="signupFormInput"
                            />
                          )}
                        </div>
                      )}
                      {shippingType === "pickup" && (
                        <select
                          value={shippingAd}
                          onChange={handlePickupOption}
                          className="form-select form-select-lg mt-3 mb-3"
                          style={{ fontSize: 16 }}
                        >
                          <option>Please select a pick up location</option>
                          <optgroup label="Lagos">
                            <option>
                              23B, Fatai Atere Way, Matori Industrial Estate,
                              Oshodi, Lagos.
                            </option>
                          </optgroup>
                          <optgroup label="Lekki">
                            <option>
                              33B, Adebayo Doherty Street, Road 14, Lekki Phase 1, Lagos.
                            </option>
                          </optgroup>
                          <optgroup label="FCT">
                            <option>
                              Suite 2.5, Willand plaza Wuse zone 4 opposite
                              zenith bank, Abuja.
                            </option>
                          </optgroup>
                          <optgroup label="Kaduna">
                            <option>
                              1, Mohammed Buhari way by NEPA round about behind
                              Total fuel station, Kaduna.
                            </option>
                          </optgroup>
                          <optgroup label="Ondo">
                            <option>39, Oba Adesida Road, Akure.</option>
                          </optgroup>
                          <optgroup label="Oyo">
                            <option>
                              Beta courier service, 1st floor, Lister building,
                              Ring Road, Ibadan.
                            </option>
                          </optgroup>
                          <optgroup label="Delta">
                            <option>
                              Beta courier, 57, Effurun/sapele road inside
                              Esiton filling station by Urhoboh college, Warri.
                            </option>
                          </optgroup>
                        </select>
                      )}
                    </Col>
                    <Button
                      size="lg"
                      type="submit"
                      disabled={validatePlaceOrder()}
                      onClick={() => handlePlaceOrder()}
                      style={{
                        borderRadius: 10,
                        backgroundColor: Colors.BES_PURPLE,
                        color: "#FFFFFF",
                        fontSize: 16,
                        width: "100%",
                        marginTop: 20,
                        marginBottom: 25,
                        height: 45,
                        border: "none",
                      }}
                      className="load-cart-button bes-input-box-shadow"
                    >
                      {isLoading ? (
                        <div className="d-flex align-items-center justify-content-center">
                          <Oval
                            type="Circles"
                            color="#FFFFFF"
                            height={30}
                            width={30}
                            wrapperStyle={{ marginLeft: 15 }}
                          />
                        </div>
                      ) : (
                        "Place Order"
                      )}
                    </Button>
                  </div>
                </Row>
              </Fragment>
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
};

export default LoadCartPage;
