import React, { useState } from "react";
import { Colors } from "../../utils/styles";
import { Separator } from "./components/PaymentOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faCreditCard,
  faUniversity,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import SideBar from "../../components/SideBar";
import Header from "../../components/Header";
import ActionConfirmationModal from "../../components/ActionConfirmation";

const FundingOptions = ({ title, onClick, icon, descText }) => {
  return (
    <div onClick={onClick} style={styles.container} className="mobile-wallet-width">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="d-flex align-items-center">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 30,
              width: 30,
              backgroundColor: Colors.GRAY_LIGHT,
              borderRadius: 50,
            }}
          >
            <FontAwesomeIcon icon={icon} color={Colors.BES_BLACK} size={20} />
          </div>
          <div>
            <p style={styles.optionsText}>{title}</p>
            <p style={styles.descText}>{descText}</p>
          </div>
        </div>
        <FontAwesomeIcon
          icon={faChevronRight}
          color={Colors.GRAY_MEDIUM}
          size="xs"
        />
      </div>
    </div>
  );
};

const FundWallet = () => {
  // const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const fundingType = location.state.fundingType;
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <Header hideAuth={true} />
      <div className="custom_container custom_container--pall">
        <section style={{ height: "90vh", marginTop: 20 }}>
          <div className="custom-row">
            <div
              style={{
                padding: "10px 0 0 0",
              }}
              className="profile-sidebar first"
            >
              <SideBar path="/wallet" />
            </div>
            <div
              className="second px-4"
              style={{
                boxShadow: "none",
              }}
            >
              <FundingOptions
                title={`${
                  fundingType === "Naira" ? "Naira" : "Dollar"
                } Bank Transfer`}
                icon={faUniversity}
                descText="Transfer to Beta-eshopping account number"
                onClick={() => setShowModal(true)}
              />
              <Separator width="85%" />
              <FundingOptions
                title="Debit / Credit card"
                icon={faCreditCard}
                descText={`Fund with a ${
                  fundingType === "Naira" ? "Naira" : "Dollar"
                } card`}
                onClick={() =>
                  navigate("/wallet/fund/card", { state: fundingType })
                }
              />
            </div>
            <ActionConfirmationModal
              visible={showModal}
              // size="md"
              title={`${
                fundingType === "Naira" ? "Naira" : "Dollar"
              } Bank Transfer`}
              imageIcon={require("./assets/logo-icon.png")}
              headerText="Bank Details"
              bodyText={`Bank: Guaranty Trust Bank Ltd.
              \n Account Name: Beta Courier Services Ltd.
              ${
                fundingType === "Dollar"
                  ? `\nSort Code: 058-152023.
                  \nSwift Code: GTBINGLA
                  \nNUBAN: 0679125931
                  \nAccount No: 0679125931`
                  : "\nAccount No: 0002887073"
              }
              \nAddress: 31 Mobolaji Bank Anthony Way, Ikeja, Lagos, Nigeria.
              \nKindly send proof of payment to info@beta-eshopping.com.`}
              onClose={() => setShowModal(false)}
              actionButtonTitle="Done"
              onActionButton={() => setShowModal(false)}
              bodyTextStyle={styles.dollarModalText}
              mainContainerStyle={styles.paymentConfirmationContainer}
              headerTextStyle={{
                paddingVertical: 10,
                fontSize: 22,
              }}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default FundWallet;

const styles = {
  container: {
    padding: "15px 5px 15px 5px",
    paddingHorizontal: 5,
    marginBottom: 5,
    cursor: "pointer",
    width: "85%",
  },
  image: {
    width: 20,
    height: 20,
    borderRadius: 50,
  },
  dollarModalText: {
    lineHeight: 18,
    textAlign: "center",
    color: Colors.BES_BLACK,
  },
  paymentConfirmationContainer: {
    height: "90%",
  },
  optionsText: {
    fontSize: 16,
    paddingLeft: 10,
    marginBottom: 0,
    fontWeight: "500",
    color: Colors.BES_BLACK,
  },
  descText: {
    color: Colors.GRAY_DARK,
    paddingLeft: 10,
    paddingTop: 2,
    fontSize: 12,
    marginBottom: 0,
  },
};
